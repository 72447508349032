<template>
  <div class="foot">
    <div>
      <p class="flex-a"><i class="el-icon-printer" style="color:#5280FF;font-size: 24px;"></i><span>传真：0793-3409556</span>
      </p>
      <p class="flex-a"><i class="el-icon-phone"
          style="color:#5280FF;font-size: 24px;"></i><span>咨询电话：19179363419(蒋经理)</span></p>
      <p class="flex-a"><i class="el-icon-location"
          style="color:#5280FF;font-size: 24px;"></i><span>公司地址：江西省上饶市余干县玉亭镇鄱阳湖大道新建设大厦8楼</span></p>
    </div>
    <div class="link">
      <span>友情链接：</span> <a href="https://ggzy.jiangxi.gov.cn/web/" target="_blank">江西公共资源交易网</a>| <a
        href="http://www.ygxzfwzx.com/" target="_blank">余干县行政服务中心和余干县公共资源交易中心</a>
    </div>
    <div>
      <span>版权所有：余干县城市建设投资开发集团有限公司</span>
      <span class="link">
        ICP备案号
        <a href="https://beian.miit.gov.cn/#/Integrated/index">赣ICP备2022007552号</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foot',
  components: {},
  props: {

  },
  data() {
    return {

    }
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {

  },
  computed: {

  }
}
</script>

<style lang="less">
.foot {
  width: 100%;
  background: #112547;
  padding: 10px 20%;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 22px;

  .link {
    a {
      color: #fff;
      margin: 0 15px;
    }
  }
}
</style>
